import {useState, useEffect} from "react";
import {Link} from "react-router-dom";
import Logo from '../../img/transparentlogo.png';

const Navbar = ()=>{
  const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const onScroll = () => {
        if (window.scrollY > 50) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
}, [])

const mainFun = ()=>{
  setIsMobile(!isMobile)
}
const closeBar = ()=>{
  setIsMobile(false)
  window.scrollTo(0, 0)
}
    return(
      <header id="header" className={`header fixed-top ${scrolled ? 'header-scrolled':''}`}>
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
      <Link to='/' className="home" onClick={closeBar}>
        <a className="logo d-flex align-items-center">
          <img src={Logo} alt="IndiaNextGen" />
        </a>
        </Link>
        <nav id="navbar" className={`${isMobile ? 'navbar-mobile' : 'navbar'}`}>
          <ul>
          <Link to='/' className="home" onClick={closeBar}>
            <li><a className="nav-link scrollto active">Home</a></li>
          </Link>
          <Link to='/about' className="about" onClick={closeBar}>
            <li><a className="nav-link scrollto">About Us</a></li>
          </Link>
          <Link to='/career' className="home" onClick={closeBar}>
            <li><a className="nav-link scrollto">Career</a></li>
            </Link>  
          {/* <Link onClick={closeBar}>  */}
            <li><a className="nav-link scrollto getstarted" href="#contact">Contact Us</a></li> 
          {/* </Link>  */}
            {/* //getstarted */}
          </ul>
          <i className={`bi mobile-nav-toggle ${isMobile ?'bi-x':'bi-list'}`} onClick={mainFun} />
        </nav>{/* .navbar */}
      </div>
    </header>
    )
}
export default Navbar