import {useState, useEffect} from "react";
import Logo from '../../img/transparentlogo.png';
import Footer from '../../img/skatter-programmer.gif'
import Hero from '../../img/hero-img.png';
import Feature from '../../img/features.png';
import Value1 from '../../img/values-1.png';
import Value2 from '../../img/values-2.png';
import Value3 from '../../img/values-3.png';
import Feature2 from '../../img/features-2.png';
import {Link} from "react-router-dom";

const HomePage = ()=>{
              return (
                <div>
                  {/* ======= Hero Section ======= */}
                  <section id="hero" className="hero d-flex align-items-center">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center">
                          <h1 data-aos="fade-up">Crafting digital solutions that Transform Business</h1>
                          <h2 data-aos="fade-up" data-aos-delay={400}>We specialize in developing digital solutions that have the power to revolutionize business.</h2>
                          <div data-aos="fade-up" data-aos-delay={600}>
                            <div className="text-center text-lg-start">
                              <a href="#contact" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                <span>Let's Talk</span>
                                <i className="bi bi-arrow-right" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay={200}>
                          <img src={Hero} className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                  </section>{/* End Hero */}
                  {/* ======= Features Section ======= */}
                  <section id="features" className="features">
                    <div className="container" data-aos="fade-up">
                      <header className="section-header">
                        <h2>Why Business Prefer Us?</h2>
                        <p> Our focus is on crafting transformative strategies and technologies that drive growth, increase efficiency, and enhance customer experiences.</p>
                      </header>
                      <div className="row">
                        <div className="col-lg-7 mt-5 mt-lg-0 d-flex">
                          <div className="row align-self-center gy-4">
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay={200}>
                              <div className="feature-box d-flex align-items-center">
                                <i className="bi bi-check" />
                                <h3>Agile Development Process</h3>
                              </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay={300}>
                              <div className="feature-box d-flex align-items-center">
                                <i className="bi bi-check" />
                                <h3>Certified &amp; Vetted Developers</h3>
                              </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay={400}>
                              <div className="feature-box d-flex align-items-center">
                                <i className="bi bi-check" />
                                <h3>World Class Infrastructure</h3>
                              </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay={500}>
                              <div className="feature-box d-flex align-items-center">
                                <i className="bi bi-check" />
                                <h3>Flexible Hiring Models</h3>
                              </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay={600}>
                              <div className="feature-box d-flex align-items-center">
                                <i className="bi bi-check" />
                                <h3>Transparent Project Management</h3>
                              </div>
                            </div>
                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay={700}>
                              <div className="feature-box d-flex align-items-center">
                                <i className="bi bi-check" />
                                <h3>Strict NDA to protect IP Rights</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <img src={Feature} className="img-fluid" alt="" />
                        </div>
                      </div> {/* / row */}
                      {/* ======= Services Section ======= */}
                      <section id="services" className="services">
                        <div className="container" data-aos="fade-up">
                          <header className="section-header">
                            <h2>Industries</h2>
                            <p>We create game-changing digital experiences for customers by leveraging cutting-edge technologies
                              and building scalable solutions.</p>
                          </header>
                          <div className="row gy-4">
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={200}>
                              <div className="service-box blue">
                                <i className="ri-discuss-line icon" />
                                <h3>Healthcare</h3>
                                <ul style={{listStyleType: 'none'}}>
                                  <li><i className="bi bi-check-circle-fill" />Telemedicine Solution.</li>
                                  <li><i className="bi bi-check-circle-fill" />Electronic Health Records(EHR).</li>
                                  <li><i className="bi bi-check-circle-fill" />Practice Management Systems.</li>
                                  <li><i className="bi bi-check-circle-fill" />Clinical Software Solutions.</li>
                                  <li><i className="bi bi-check-circle-fill" /> Medical Device Integrations Solutions.</li>
                                </ul>
                                {/* <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a> */}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={300}>
                              <div className="service-box orange">
                                <i className="ri-discuss-line icon" />
                                <h3>Banking &amp; Finance</h3>
                                <ul style={{listStyleType: 'none'}}>
                                  <li><i className="bi bi-check-circle-fill" />Digital Payment Systems</li>
                                  <li><i className="bi bi-check-circle-fill" />Custom Digital Banking Solutions</li>
                                  <li><i className="bi bi-check-circle-fill" />Wealth/Finance Management Solutions</li>
                                  <li><i className="bi bi-check-circle-fill" />Mobile Payment App Development</li> 
                                  <li><i className="bi bi-check-circle-fill" />Insurance App Development</li>
                                </ul>
                                {/* <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a> */}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={400}>
                              <div className="service-box green">
                                <i className="ri-discuss-line icon" />
                                <h3>Fitness &amp; Sports</h3>
                                <ul style={{listStyleType: 'none'}}>
                                  <li><i className="bi bi-check-circle-fill" />Sports Activities Tracking Application.</li>
                                  <li><i className="bi bi-check-circle-fill" />Nutritional Fitness Apps.</li>
                                  <li><i className="bi bi-check-circle-fill" />On-demand personal trainer app.</li>
                                  <li><i className="bi bi-check-circle-fill" />Workout planning apps.</li>
                                  <li><i className="bi bi-check-circle-fill" />Gym and yoga apps.</li>
                                </ul>
                                {/* <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a> */}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={500}>
                              <div className="service-box red">
                                <i className="ri-discuss-line icon" />
                                {/* <img src="assets/img/game.jpeg"/> */}
                                <h3>Gaming</h3>
                                <ul style={{listStyleType: 'none'}}>
                                  <li><i className="bi bi-check-circle-fill" />Adventure Gaming Apps.</li>
                                  <li><i className="bi bi-check-circle-fill" />Arcade Gaming Apps.</li>
                                  <li><i className="bi bi-check-circle-fill" />Role-Playing Games or RPG.</li>
                                  <li><i className="bi bi-check-circle-fill" />Multiplayer Online Battle Arena Gaming Apps (MOBA).</li>
                                  <li><i className="bi bi-check-circle-fill" />Strategy Gaming Apps.</li>
                                </ul>
                                {/* <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a> */}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={600}>
                              <div className="service-box purple">
                                <i className="ri-discuss-line icon" />
                                <h3>Media &amp; Entertainment</h3>
                                <ul style={{listStyleType: 'none'}}>
                                  <li><i className="bi bi-check-circle-fill" />Movie Streaming App Development.</li>
                                  <li><i className="bi bi-check-circle-fill" />Photo Editing Apps.</li>
                                  <li><i className="bi bi-check-circle-fill" />Comics App.</li>
                                  <li><i className="bi bi-check-circle-fill" />Social Media App</li>
                                  <li><i className="bi bi-check-circle-fill" />Video &amp; Music App.</li>
                                </ul>
                                {/* <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a> */}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={700}>
                              <div className="service-box pink">
                                <i className="ri-discuss-line icon" />
                                <h3>Retail &amp; E-Commerce</h3>
                                <ul style={{listStyleType: 'none'}}>
                                  <li><i className="bi bi-check-circle-fill" />Custom eCommerce Store Development</li>
                                  <li><i className="bi bi-check-circle-fill" />Shipping and fulfillment Solution</li>
                                  <li><i className="bi bi-check-circle-fill" />Grocery E-Commerce Apps</li>
                                  <li><i className="bi bi-check-circle-fill" />End to End MarketPlace</li>
                                  <li><i className="bi bi-check-circle-fill" />AR-based eCommerce solutions</li> 
                                </ul>
                                {/* <a href="#" class="read-more"><span>Read More</span> <i class="bi bi-arrow-right"></i></a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>{/* End Services Section */}
                      {/* ======= Values Section ======= */}
                      <section id="values" className="values">
                        <div className="container" data-aos="fade-up">
                          <header className="section-header">
                            <h2>Our Process</h2>
                            <p>We love What we Do</p>
                          </header>
                          <div className="row">
                            <div className="col-lg-4" data-aos="fade-up" data-aos-delay={200}>
                              <div className="box">
                                <img src={Value1} className="img-fluid" alt="" />
                                <h3>Implementation</h3>
                                <p>During implementation, developers write code according to design specifications while following industry-standard coding practices such as modular programming, code commenting and version control. Code is tested using various techniques like unit testing, integration testing or system testing to ensure that it meets functional requirements and performs as expected.</p>
                              </div>
                            </div>
                            <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={400}>
                              <div className="box">
                                <img src={Value2} className="img-fluid" alt="" />
                                <h3>Testing</h3>
                                <p>The testing phase typically includes several types of tests, such as unit testing, integration testing, system testing, acceptance testing and regression testing.</p>
                              </div>
                            </div>
                            <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay={600}>
                              <div className="box">
                                <img src={Value3} className="img-fluid" alt="" />
                                <h3>Evolution</h3>
                                <p>Maintenance activities include bug fixing, patching or enhancements to fix issues discovered after release or add new features that were not originally included during development. Updates are made to address security vulnerabilities, improve performance and stability while also ensuring compliance with changing industry standards/regulations.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>{/* End Values Section */}
                      {/* Feature Tabs */}
                      <div className="row feture-tabs" data-aos="fade-up">
                        <div className="col-lg-6">
                          <h3>Delivering Innovation That Thrives Your Business</h3>
                          {/* Tabs */}
                          <ul className="nav nav-pills mb-3">
                            <li>
                              <a className="nav-link active" data-bs-toggle="pill" href="#tab1">Digital Marketing</a>
                            </li>
                            <li>
                              <a className="nav-link" data-bs-toggle="pill" href="#tab2">Web Development</a>
                            </li>
                            <li>
                              <a className="nav-link" data-bs-toggle="pill" href="#tab3">Mobile Development</a>
                            </li>
                          </ul>{/* End Tabs */}
                          {/* Tab Content */}
                          <div className="tab-content">
                            <div className="tab-pane fade show active" id="tab1">
                              <div className="d-flex align-items-center mb-2">
                                <i className="bi bi-check2" />
                                <h4>Digital Marketing</h4>
                              </div>
                              <p>Digital marketing encompasses all marketing efforts and strategies that increase a business presence across all digital media platforms such as search engine, social media, and email marketing to connect with ongoing and prospective customers.</p>
                            </div>{/* End Tab 1 Content */}
                            <div className="tab-pane fade show" id="tab2">
                              <div className="d-flex align-items-center mb-2">
                                <i className="bi bi-check2" />
                                <h4>Web Development</h4>
                              </div>
                              <p>Digitally transform your business and build a strong online presence by developing robust, scalable, light-weight and modern web applications.</p>
                            </div>{/* End Tab 2 Content */}
                            <div className="tab-pane fade show" id="tab3">
                              <div className="d-flex align-items-center mb-2">
                                <i className="bi bi-check2" />
                                <h4>&gt;Mobile Development</h4>
                              </div>
                              <p>Expand your market reach and improve your user experience by developing mobile apps for Android, iOS, &amp; windows.</p>
                            </div>{/* End Tab 3 Content */}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <img src={Feature2} className="img-fluid" alt="" />
                        </div>
                      </div>{/* End Feature Tabs */}
                      {/* ======= F.A.Q Section ======= */}
                      <section id="faq" className="faq">
                        <div className="container" data-aos="fade-up">
                          <header className="section-header">
                            <h2>Delivering Innovation That Thrives Your Business</h2>
                            {/* <p>Frequently Asked Questions</p> */}
                          </header>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="accordion accordion-flush" id="faqlist1">
                                <div className="accordion-item">
                                  <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                      DIGITAL MARKETING
                                    </button>
                                  </h2>
                                  <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div className="accordion-body">
                                      Digital marketing encompasses all marketing efforts and strategies that increase a business presence across all digital media platforms such as search engine, social media, and email marketing to connect with ongoing and prospective customers.
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item">
                                  <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                      Mobile App Development
                                    </button>
                                  </h2>
                                  <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div className="accordion-body">
                                      Expand your market reach and improve your user experience by developing mobile apps for Android, iOS, &amp; windows.
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item">
                                  <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                      Web Development
                                    </button>
                                  </h2>
                                  <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div className="accordion-body">
                                      Digitally transform your business and build a strong online presence by developing robust, scalable, light-weight and modern web applications.
                                    </div>
                                  </div>
                                </div>
                                <div className="accordion-item">
                                  <h2 className="accordion-header">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                      Product Design &amp; Strategy
                                    </button>
                                  </h2>
                                  <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                                    <div className="accordion-body">
                                      We follow end-to-end agile product development approach to Deliver robust &amp; scalable Digital Products.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              {/* <div class="accordion accordion-flush" id="faqlist2">
          
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-1">
                              Product Design & Strategy
                            </button>
                          </h2>
                          <div id="faq2-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist2">
                            <div class="accordion-body">
                              We follow end-to-end agile product development approach to Deliver robust & scalable Digital Products.
                            </div>
                          </div>
                        </div>
                      </div> */}
                            </div>
                          </div>
                        </div>
                      </section>
                      {/* End F.A.Q Section */}
                      {/* Feature Icons */}
                      {/* <div class="row feature-icons" data-aos="fade-up">
                    <h3>Ratione mollitia eos ab laudantium rerum beatae quo</h3>
          
                    <div class="row">
          
                      <div class="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
                        <img src="assets/img/features-3.png" class="img-fluid p-4" alt="">
                      </div>
          
                      <div class="col-xl-8 d-flex content">
                        <div class="row align-self-center gy-4">
          
                          <div class="col-md-6 icon-box" data-aos="fade-up">
                            <i class="ri-line-chart-line"></i>
                            <div>
                              <h4>Corporis voluptates sit</h4>
                              <p>Consequuntur sunt aut quasi enim aliquam quae harum pariatur laboris nisi ut aliquip</p>
                            </div>
                          </div>
          
                          <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                            <i class="ri-stack-line"></i>
                            <div>
                              <h4>Ullamco laboris nisi</h4>
                              <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt</p>
                            </div>
                          </div>
          
                          <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                            <i class="ri-brush-4-line"></i>
                            <div>
                              <h4>Labore consequatur</h4>
                              <p>Aut suscipit aut cum nemo deleniti aut omnis. Doloribus ut maiores omnis facere</p>
                            </div>
                          </div>
          
                          <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                            <i class="ri-magic-line"></i>
                            <div>
                              <h4>Beatae veritatis</h4>
                              <p>Expedita veritatis consequuntur nihil tempore laudantium vitae denat pacta</p>
                            </div>
                          </div>
          
                          <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                            <i class="ri-command-line"></i>
                            <div>
                              <h4>Molestiae dolor</h4>
                              <p>Et fuga et deserunt et enim. Dolorem architecto ratione tensa raptor marte</p>
                            </div>
                          </div>
          
                          <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                            <i class="ri-radar-line"></i>
                            <div>
                              <h4>Explicabo consectetur</h4>
                              <p>Est autem dicta beatae suscipit. Sint veritatis et sit quasi ab aut inventore</p>
                            </div>
                          </div>
          
                        </div>
                      </div>
          
                    </div>
          
                  </div> */}
                      {/* End Feature Icons */}
                    </div>
                  </section>{/* End Features Section */}
                  {/* <main id="main">
              <section id="about" class="about">
                <div class="container" data-aos="fade-up">
                  <div class="row gx-0">
          
                    <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                      <div class="content">
                        <h3>Why Business Prefer Us?</h3>
                        <p>
                          IndiaNextGen offers the opportunity to collaborate with top-tier global organizations and their senior executives in tackling complex challenges & providing the world with the latest technology aspects. This level of exposure can significantly enhance your career prospects, while working alongside dedicated colleagues who are committed to supporting your growth and development. If this resonates with you, IndiaNextGen may be the ideal place for advancing your career to new heights.
                        </p>
                        <div class="text-center text-lg-start">
                          <a href="#" class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                            <span>Read More</span>
                            <i class="bi bi-arrow-right"></i>
                          </a>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                      <img src="assets/img/about.jpg" class="img-fluid" alt="">
                    </div>
          
                  </div>
                </div>
          
              </section> */}
                  {/* End About Section */}
                  {/* ======= Counts Section ======= */}
                  {/* <section id="counts" class="counts">
                <div class="container" data-aos="fade-up">
          
                  <div class="row gy-4">
          
                    <div class="col-lg-3 col-md-6">
                      <div class="count-box">
                        <i class="bi bi-emoji-smile"></i>
                        <div>
                          <span data-purecounter-start="0" data-purecounter-end="232" data-purecounter-duration="1" class="purecounter"></span>
                          <p>Happy Clients</p>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-3 col-md-6">
                      <div class="count-box">
                        <i class="bi bi-journal-richtext" style="color: #ee6c20;"></i>
                        <div>
                          <span data-purecounter-start="0" data-purecounter-end="521" data-purecounter-duration="1" class="purecounter"></span>
                          <p>Projects</p>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-3 col-md-6">
                      <div class="count-box">
                        <i class="bi bi-headset" style="color: #15be56;"></i>
                        <div>
                          <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" class="purecounter"></span>
                          <p>Hours Of Support</p>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-3 col-md-6">
                      <div class="count-box">
                        <i class="bi bi-people" style="color: #bb0852;"></i>
                        <div>
                          <span data-purecounter-start="0" data-purecounter-end="15" data-purecounter-duration="1" class="purecounter"></span>
                          <p>Hard Workers</p>
                        </div>
                      </div>
                    </div>
          
                  </div>
          
                </div>
              </section> */}
                  {/* End Counts Section */}
                  {/* ======= Pricing Section ======= */}
                  {/* <section id="pricing" class="pricing">
          
                <div class="container" data-aos="fade-up">
          
                  <header class="section-header">
                    <h2>Pricing</h2>
                    <p>Check our Pricing</p>
                  </header>
          
                  <div class="row gy-4" data-aos="fade-left">
          
                    <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                      <div class="box">
                        <h3 style="color: #07d5c0;">Free Plan</h3>
                        <div class="price"><sup>$</sup>0<span> / mo</span></div>
                        <img src="assets/img/pricing-free.png" class="img-fluid" alt="">
                        <ul>
                          <li>Aida dere</li>
                          <li>Nec feugiat nisl</li>
                          <li>Nulla at volutpat dola</li>
                          <li class="na">Pharetra massa</li>
                          <li class="na">Massa ultricies mi</li>
                        </ul>
                        <a href="#" class="btn-buy">Buy Now</a>
                      </div>
                    </div>
          
                    <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                      <div class="box">
                        <span class="featured">Featured</span>
                        <h3 style="color: #65c600;">Starter Plan</h3>
                        <div class="price"><sup>$</sup>19<span> / mo</span></div>
                        <img src="assets/img/pricing-starter.png" class="img-fluid" alt="">
                        <ul>
                          <li>Aida dere</li>
                          <li>Nec feugiat nisl</li>
                          <li>Nulla at volutpat dola</li>
                          <li>Pharetra massa</li>
                          <li class="na">Massa ultricies mi</li>
                        </ul>
                        <a href="#" class="btn-buy">Buy Now</a>
                      </div>
                    </div>
          
                    <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
                      <div class="box">
                        <h3 style="color: #ff901c;">Business Plan</h3>
                        <div class="price"><sup>$</sup>29<span> / mo</span></div>
                        <img src="assets/img/pricing-business.png" class="img-fluid" alt="">
                        <ul>
                          <li>Aida dere</li>
                          <li>Nec feugiat nisl</li>
                          <li>Nulla at volutpat dola</li>
                          <li>Pharetra massa</li>
                          <li>Massa ultricies mi</li>
                        </ul>
                        <a href="#" class="btn-buy">Buy Now</a>
                      </div>
                    </div>
          
                    <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
                      <div class="box">
                        <h3 style="color: #ff0071;">Ultimate Plan</h3>
                        <div class="price"><sup>$</sup>49<span> / mo</span></div>
                        <img src="assets/img/pricing-ultimate.png" class="img-fluid" alt="">
                        <ul>
                          <li>Aida dere</li>
                          <li>Nec feugiat nisl</li>
                          <li>Nulla at volutpat dola</li>
                          <li>Pharetra massa</li>
                          <li>Massa ultricies mi</li>
                        </ul>
                        <a href="#" class="btn-buy">Buy Now</a>
                      </div>
                    </div>
          
                  </div>
          
                </div>
          
              </section> */}
                  {/* End Pricing Section */}
                  {/* ======= Portfolio Section ======= */}
                  {/* <section id="portfolio" class="portfolio">
          
                <div class="container" data-aos="fade-up">
          
                  <header class="section-header">
                    <h2>Portfolio</h2>
                    <p>Check our latest work</p>
                  </header>
          
                  <div class="row" data-aos="fade-up" data-aos-delay="100">
                    <div class="col-lg-12 d-flex justify-content-center">
                      <ul id="portfolio-flters">
                        <li data-filter="*" class="filter-active">All</li>
                        <li data-filter=".filter-app">App</li>
                        <li data-filter=".filter-card">Card</li>
                        <li data-filter=".filter-web">Web</li>
                      </ul>
                    </div>
                  </div>
          
                  <div class="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
          
                    <div class="col-lg-4 col-md-6 portfolio-item filter-app">
                      <div class="portfolio-wrap">
                        <img src="assets/img/portfolio/portfolio-1.jpg" class="img-fluid" alt="">
                        <div class="portfolio-info">
                          <h4>App 1</h4>
                          <p>App</p>
                          <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfokio-lightbox" title="App 1"><i class="bi bi-plus"></i></a>
                            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                      <div class="portfolio-wrap">
                        <img src="assets/img/portfolio/portfolio-2.jpg" class="img-fluid" alt="">
                        <div class="portfolio-info">
                          <h4>Web 3</h4>
                          <p>Web</p>
                          <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" class="portfokio-lightbox" title="Web 3"><i class="bi bi-plus"></i></a>
                            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-4 col-md-6 portfolio-item filter-app">
                      <div class="portfolio-wrap">
                        <img src="assets/img/portfolio/portfolio-3.jpg" class="img-fluid" alt="">
                        <div class="portfolio-info">
                          <h4>App 2</h4>
                          <p>App</p>
                          <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" class="portfokio-lightbox" title="App 2"><i class="bi bi-plus"></i></a>
                            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-4 col-md-6 portfolio-item filter-card">
                      <div class="portfolio-wrap">
                        <img src="assets/img/portfolio/portfolio-4.jpg" class="img-fluid" alt="">
                        <div class="portfolio-info">
                          <h4>Card 2</h4>
                          <p>Card</p>
                          <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" class="portfokio-lightbox" title="Card 2"><i class="bi bi-plus"></i></a>
                            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                      <div class="portfolio-wrap">
                        <img src="assets/img/portfolio/portfolio-5.jpg" class="img-fluid" alt="">
                        <div class="portfolio-info">
                          <h4>Web 2</h4>
                          <p>Web</p>
                          <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" class="portfokio-lightbox" title="Web 2"><i class="bi bi-plus"></i></a>
                            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-4 col-md-6 portfolio-item filter-app">
                      <div class="portfolio-wrap">
                        <img src="assets/img/portfolio/portfolio-6.jpg" class="img-fluid" alt="">
                        <div class="portfolio-info">
                          <h4>App 3</h4>
                          <p>App</p>
                          <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" class="portfokio-lightbox" title="App 3"><i class="bi bi-plus"></i></a>
                            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-4 col-md-6 portfolio-item filter-card">
                      <div class="portfolio-wrap">
                        <img src="assets/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
                        <div class="portfolio-info">
                          <h4>Card 1</h4>
                          <p>Card</p>
                          <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" class="portfokio-lightbox" title="Card 1"><i class="bi bi-plus"></i></a>
                            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-4 col-md-6 portfolio-item filter-card">
                      <div class="portfolio-wrap">
                        <img src="assets/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
                        <div class="portfolio-info">
                          <h4>Card 3</h4>
                          <p>Card</p>
                          <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" class="portfokio-lightbox" title="Card 3"><i class="bi bi-plus"></i></a>
                            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-4 col-md-6 portfolio-item filter-web">
                      <div class="portfolio-wrap">
                        <img src="assets/img/portfolio/portfolio-9.jpg" class="img-fluid" alt="">
                        <div class="portfolio-info">
                          <h4>Web 3</h4>
                          <p>Web</p>
                          <div class="portfolio-links">
                            <a href="assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" class="portfokio-lightbox" title="Web 3"><i class="bi bi-plus"></i></a>
                            <a href="portfolio-details.html" title="More Details"><i class="bi bi-link"></i></a>
                          </div>
                        </div>
                      </div>
                    </div>
          
                  </div>
          
                </div>
          
              </section> */}
                  {/* End Portfolio Section */}
                  {/* ======= Testimonials Section ======= */}
                  {/* <section id="testimonials" class="testimonials">
          
                <div class="container" data-aos="fade-up">
          
                  <header class="section-header">
                    <h2>Testimonials</h2>
                    <p>What they are saying about us</p>
                  </header>
          
                  <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="200">
                    <div class="swiper-wrapper">
          
                      <div class="swiper-slide">
                        <div class="testimonial-item">
                          <div class="stars">
                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                          </div>
                          <p>
                            Proin iaculis purus consequat sem cure digni ssim donec porttitora entum suscipit rhoncus. Accusantium quam, ultricies eget id, aliquam eget nibh et. Maecen aliquam, risus at semper.
                          </p>
                          <div class="profile mt-auto">
                            <img src="assets/img/testimonials/testimonials-1.jpg" class="testimonial-img" alt="">
                            <h3>Saul Goodman</h3>
                            <h4>Ceo &amp; Founder</h4>
                          </div>
                        </div>
                      </div>
          
                      <div class="swiper-slide">
                        <div class="testimonial-item">
                          <div class="stars">
                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                          </div>
                          <p>
                            Export tempor illum tamen malis malis eram quae irure esse labore quem cillum quid cillum eram malis quorum velit fore eram velit sunt aliqua noster fugiat irure amet legam anim culpa.
                          </p>
                          <div class="profile mt-auto">
                            <img src="assets/img/testimonials/testimonials-2.jpg" class="testimonial-img" alt="">
                            <h3>Sara Wilsson</h3>
                            <h4>Designer</h4>
                          </div>
                        </div>
                      </div>
          
                      <div class="swiper-slide">
                        <div class="testimonial-item">
                          <div class="stars">
                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                          </div>
                          <p>
                            Enim nisi quem export duis labore cillum quae magna enim sint quorum nulla quem veniam duis minim tempor labore quem eram duis noster aute amet eram fore quis sint minim.
                          </p>
                          <div class="profile mt-auto">
                            <img src="assets/img/testimonials/testimonials-3.jpg" class="testimonial-img" alt="">
                            <h3>Jena Karlis</h3>
                            <h4>Store Owner</h4>
                          </div>
                        </div>
                      </div>
          
                      <div class="swiper-slide">
                        <div class="testimonial-item">
                          <div class="stars">
                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                          </div>
                          <p>
                            Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                          </p>
                          <div class="profile mt-auto">
                            <img src="assets/img/testimonials/testimonials-4.jpg" class="testimonial-img" alt="">
                            <h3>Matt Brandon</h3>
                            <h4>Freelancer</h4>
                          </div>
                        </div>
                      </div>
          
                      <div class="swiper-slide">
                        <div class="testimonial-item">
                          <div class="stars">
                            <i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i><i class="bi bi-star-fill"></i>
                          </div>
                          <p>
                            Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum quid.
                          </p>
                          <div class="profile mt-auto">
                            <img src="assets/img/testimonials/testimonials-5.jpg" class="testimonial-img" alt="">
                            <h3>John Larson</h3>
                            <h4>Entrepreneur</h4>
                          </div>
                        </div>
                      </div>
          
                    </div>
                    <div class="swiper-pagination"></div>
                  </div>
                </div>
              </section> */}
                  {/* End Testimonials Section */}
                  {/* ======= Team Section ======= */}
                  {/* <section id="team" class="team">
          
                <div class="container" data-aos="fade-up">
          
                  <header class="section-header">
                    <h2>Team</h2>
                    <p>Our hard working team</p>
                  </header>
          
                  <div class="row gy-4">
          
                    <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
                      <div class="member">
                        <div class="member-img">
                          <img src="assets/img/team/team-1.jpg" class="img-fluid" alt="">
                          <div class="social">
                            <a href=""><i class="bi bi-twitter"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i></a>
                          </div>
                        </div>
                        <div class="member-info">
                          <h4>Walter White</h4>
                          <span>Chief Executive Officer</span>
                          <p>Velit aut quia fugit et et. Dolorum ea voluptate vel tempore tenetur ipsa quae aut. Ipsum exercitationem iure minima enim corporis et voluptate.</p>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
                      <div class="member">
                        <div class="member-img">
                          <img src="assets/img/team/team-2.jpg" class="img-fluid" alt="">
                          <div class="social">
                            <a href=""><i class="bi bi-twitter"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i></a>
                          </div>
                        </div>
                        <div class="member-info">
                          <h4>Sarah Jhonson</h4>
                          <span>Product Manager</span>
                          <p>Quo esse repellendus quia id. Est eum et accusantium pariatur fugit nihil minima suscipit corporis. Voluptate sed quas reiciendis animi neque sapiente.</p>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
                      <div class="member">
                        <div class="member-img">
                          <img src="assets/img/team/team-3.jpg" class="img-fluid" alt="">
                          <div class="social">
                            <a href=""><i class="bi bi-twitter"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i></a>
                          </div>
                        </div>
                        <div class="member-info">
                          <h4>William Anderson</h4>
                          <span>CTO</span>
                          <p>Vero omnis enim consequatur. Voluptas consectetur unde qui molestiae deserunt. Voluptates enim aut architecto porro aspernatur molestiae modi.</p>
                        </div>
                      </div>
                    </div>
          
                    <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
                      <div class="member">
                        <div class="member-img">
                          <img src="assets/img/team/team-4.jpg" class="img-fluid" alt="">
                          <div class="social">
                            <a href=""><i class="bi bi-twitter"></i></a>
                            <a href=""><i class="bi bi-facebook"></i></a>
                            <a href=""><i class="bi bi-instagram"></i></a>
                            <a href=""><i class="bi bi-linkedin"></i></a>
                          </div>
                        </div>
                        <div class="member-info">
                          <h4>Amanda Jepson</h4>
                          <span>Accountant</span>
                          <p>Rerum voluptate non adipisci animi distinctio et deserunt amet voluptas. Quia aut aliquid doloremque ut possimus ipsum officia.</p>
                        </div>
                      </div>
                    </div>
          
                  </div>
          
                </div>
          
              </section> */}
                  {/* End Team Section */}
                  {/* ======= Clients Section ======= */}
                  {/* <section id="clients" class="clients">
          
                <div class="container" data-aos="fade-up">
          
                  <header class="section-header">
                    <h2>Our Clients</h2>
                    <p>Temporibus omnis officia</p>
                  </header>
          
                  <div class="clients-slider swiper">
                    <div class="swiper-wrapper align-items-center">
                      <div class="swiper-slide"><img src="assets/img/clients/client-1.png" class="img-fluid" alt=""></div>
                      <div class="swiper-slide"><img src="assets/img/clients/client-2.png" class="img-fluid" alt=""></div>
                      <div class="swiper-slide"><img src="assets/img/clients/client-3.png" class="img-fluid" alt=""></div>
                      <div class="swiper-slide"><img src="assets/img/clients/client-4.png" class="img-fluid" alt=""></div>
                      <div class="swiper-slide"><img src="assets/img/clients/client-5.png" class="img-fluid" alt=""></div>
                      <div class="swiper-slide"><img src="assets/img/clients/client-6.png" class="img-fluid" alt=""></div>
                      <div class="swiper-slide"><img src="assets/img/clients/client-7.png" class="img-fluid" alt=""></div>
                      <div class="swiper-slide"><img src="assets/img/clients/client-8.png" class="img-fluid" alt=""></div>
                    </div>
                    <div class="swiper-pagination"></div>
                  </div>
                </div>
          
              </section> */}
                  {/* End Clients Section */}
                  {/* ======= Recent Blog Posts Section ======= */}
                  {/* <section id="recent-blog-posts" class="recent-blog-posts">
          
                <div class="container" data-aos="fade-up">
          
                  <header class="section-header">
                    <h2>Blog</h2>
                    <p>Recent posts form our Blog</p>
                  </header>
          
                  <div class="row">
          
                    <div class="col-lg-4">
                      <div class="post-box">
                        <div class="post-img"><img src="assets/img/blog/blog-1.jpg" class="img-fluid" alt=""></div>
                        <span class="post-date">Tue, September 15</span>
                        <h3 class="post-title">Eum ad dolor et. Autem aut fugiat debitis voluptatem consequuntur sit</h3>
                        <a href="blog-single.html" class="readmore stretched-link mt-auto"><span>Read More</span><i class="bi bi-arrow-right"></i></a>
                      </div>
                    </div>
          
                    <div class="col-lg-4">
                      <div class="post-box">
                        <div class="post-img"><img src="assets/img/blog/blog-2.jpg" class="img-fluid" alt=""></div>
                        <span class="post-date">Fri, August 28</span>
                        <h3 class="post-title">Et repellendus molestiae qui est sed omnis voluptates magnam</h3>
                        <a href="blog-single.html" class="readmore stretched-link mt-auto"><span>Read More</span><i class="bi bi-arrow-right"></i></a>
                      </div>
                    </div>
          
                    <div class="col-lg-4">
                      <div class="post-box">
                        <div class="post-img"><img src="assets/img/blog/blog-3.jpg" class="img-fluid" alt=""></div>
                        <span class="post-date">Mon, July 11</span>
                        <h3 class="post-title">Quia assumenda est et veritatis aut quae</h3>
                        <a href="blog-single.html" class="readmore stretched-link mt-auto"><span>Read More</span><i class="bi bi-arrow-right"></i></a>
                      </div>
                    </div>
          
                  </div>
          
                </div>
          
              </section> */}
                  {/* End Recent Blog Posts Section */}
                  {/* ======= Contact Section ======= */}
                  <section id="contact" className="contact">
                    <div className="container-fluid" data-aos="fade-up">
                      <header className="section-header">
                        <h2>Contact</h2>
                        <p>Contact Us</p>
                      </header>
                      <div className="row gy-4 flex-column-reverse flex-md-row">
                        {/* <div class="col-lg-6">
          
                      <div class="row gy-4">
                        <div class="col-md-6">
                          <div class="info-box">
                            <i class="bi bi-geo-alt"></i>
                            <h3>Address</h3>
                            <p>A108 Adam Street,<br>New York, NY 535022</p>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="info-box">
                            <i class="bi bi-telephone"></i>
                            <h3>Call Us</h3>
                            <p>+1 5589 55488 55<br>+1 6678 254445 41</p>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="info-box">
                            <i class="bi bi-envelope"></i>
                            <h3>Email Us</h3>
                            <p>info@example.com<br>contact@example.com</p>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="info-box">
                            <i class="bi bi-clock"></i>
                            <h3>Open Hours</h3>
                            <p>Monday - Friday<br>9:00AM - 05:00PM</p>
                          </div>
                        </div>
                      </div>
          
                    </div> */}
                        <div className="col-lg-6 col-md-6 col-sm-12" style={{background: 'rgb(247, 247, 247)'}}> {/* order-sm-1 order-2 */}
                          <div id="map" data-aos="fade-right" data-aos-duration={1000} className="aos-init aos-animate footer-logo">
                            <img src={Footer} width={450} />
                            <h4 className="contact-des" style={{color: '#083346'}}>Thanks for visiting</h4>
                            <p className="thank" style={{color: '#083346'}}>Do you have an interesting project that I can help? Feel free to reach out to me.</p>
                          </div>
                        </div>
                        <div className="col-lg-6 p-0">
                          <form className="php-email-form pt-5" id="contact" action="https://formspree.io/f/mbjvllaa" method="POST" target="_blank">
                            <div className="row gy-4">
                              <div className="col-md-6">
                                <input type="text" name="name" className="form-control" placeholder="Your Name" required />
                              </div>
                              <div className="col-md-6">
                                <input type="email" className="form-control" name="email" placeholder="Your Email" required />
                              </div>
                              {/* <div class="col-md-12">
                            <input type="text" class="form-control" name="subject" placeholder="Subject" required>
                          </div> */}
                              <div className="col-md-12">
                                <textarea className="form-control" name="message" rows={6} placeholder="Message" required defaultValue={""} />
                              </div>
                              <div className="col-md-12 text-center">
                                {/* <div class="loading">Loading</div> */}
                                {/* <div class="error-message">Your message has been sent. Thank you!</div> */}
                                {/* <div class="sent-message">Your message has been sent. Thank you!</div> */}
                                <button type="submit">Send Message</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </section>
                  {/* End Contact Section */}
                  {/* End #main */}
                  {/* ======= Footer ======= */}
                  {/* <footer id="footer" className="footer">
                    <div className="footer-top">
                      <div className="container">
                        <div className="row gy-4">
                          <div className="col-lg-5 col-md-12 footer-info">
                            <a href="index.html" className="logo d-flex align-items-center">
                              <img src={Logo} alt="" />
                            </a>
                            <p>Seamless technology solutions tailored to your needs, delivered with expertise and excellence.</p>
                            <div className="social-links mt-3">
                              <a href="#" className="twitter"><i className="bi bi-twitter" /></a>
                              <a href="https://www.facebook.com/Indianextgen-Technologies-113780265053868" className="facebook"><i className="bi bi-facebook" /></a>
                              <a href="https://www.linkedin.com/company/indianextgen-technologies/" className="linkedin"><i className="bi bi-linkedin" /></a>
                            </div>
                          </div>
                          <div className="col-lg-2 col-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                              <li><i className="bi bi-chevron-right" /> <a href="#">Home</a></li>
                              <li><i className="bi bi-chevron-right" /> <a href="#">About us</a></li>
                              <li><i className="bi bi-chevron-right" /> <a href="#">Services</a></li>
                            </ul>
                          </div>
                          <div className="col-lg-2 col-6 footer-links">
                            <h4>Our Services</h4>
                            <ul>
                              <li><i className="bi bi-chevron-right" /> <a href="#">Web Design</a></li>
                              <li><i className="bi bi-chevron-right" /> <a href="#">Web Development</a></li>
                              <li><i className="bi bi-chevron-right" /> <a href="#">Product Management</a></li>
                              <li><i className="bi bi-chevron-right" /> <a href="#">Marketing</a></li>
                              <li><i className="bi bi-chevron-right" /> <a href="#">Graphic Design</a></li>
                            </ul>
                          </div>
                          <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                            <h4>Contact Us</h4>
                            <p>
                              A108 City Center <br />
                              Noida, NA 250345<br />
                              India <br /><br />
                              <strong>Phone:</strong> +1 8439239196<br />
                              <strong>Email:</strong> indianextgen@gmail.com<br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="copyright">
                        © Copyright <strong><span>IndiaNextGen</span></strong>. All Rights Reserved
                      </div>
                      <div className="credits">
                        Designed by <a href="https://bootstrapmade.com/">IndiaNextGen</a>
                      </div>
                    </div>
                  </footer> */}
                  <a href="#" className="back-to-top d-flex align-items-center justify-content-center" style={{color:'red'}}><i className="bi bi-arrow-up-short" /></a>
                </div>
              );
}
export default HomePage;