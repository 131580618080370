import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import HomePage from './components/HomePage.js/HomePage';
import Career from './components/Career.js/Career';
import AboutUs from './components/AboutUs.js/AboutUs';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

function App() {
    return (
        <Router>
            <Navbar/>
            <Routes>
                <Route path="/" element={<HomePage/>}/>
                <Route path="/about" element={<AboutUs/>}/>
                <Route path="/career" element={<Career/>}/>
            </Routes>
            <Footer/>
        </Router>
    );
}

export default App;
