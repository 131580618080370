import React from "react";
import './Career.css';

const Career = ()=>{
              return (
                <>
                <div className="career-page">
                      <div className="container-fluid">
                        <p>
                          Career
                        </p>
                      </div>

                    </div>  
                  <footer id="footer" className="footer">
                    <div className="footer-newsletter">
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-12 text-center">
                          <h4>No current openings yet.</h4>
                          <p>You may still drop your cv at <a href='#'>careers@indianextgen.com</a> and we will consider it as soon as we have any vacancy.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </footer>
                  </>
              );
}
export default Career;