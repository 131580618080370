import React from 'react';
import Logo from '../../img/transparentlogo.png'

const Footer = ()=>{
    return(
        <footer id="footer" className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-5 col-md-12 footer-info">
                <a href="index.html" className="logo d-flex align-items-center">
                  <img src={Logo} alt="" />
                </a>
                <p>Seamless technology solutions tailored to your needs, delivered with expertise and excellence.</p>
                <div className="social-links mt-3">
                  <a href="#" className="twitter"><i className="bi bi-twitter" /></a>
                  <a href="https://www.facebook.com/Indianextgen-Technologies-113780265053868" className="facebook"><i className="bi bi-facebook" /></a>
                  <a href="https://www.linkedin.com/company/indianextgen-technologies/" className="linkedin"><i className="bi bi-linkedin" /></a>
                </div>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i className="bi bi-chevron-right" /> <a href="#">Home</a></li>
                  <li><i className="bi bi-chevron-right" /> <a href="#">About us</a></li>
                  <li><i className="bi bi-chevron-right" /> <a href="#">Services</a></li>
                </ul>
              </div>
              <div className="col-lg-2 col-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li><i className="bi bi-chevron-right" /> <a href="#">Web Design</a></li>
                  <li><i className="bi bi-chevron-right" /> <a href="#">Web Development</a></li>
                  <li><i className="bi bi-chevron-right" /> <a href="#">Product Management</a></li>
                  <li><i className="bi bi-chevron-right" /> <a href="#">Marketing</a></li>
                  <li><i className="bi bi-chevron-right" /> <a href="#">Graphic Design</a></li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
                <h4>Contact Us</h4>
                <p>
                  D 299 SECTOR 3<br />
                  GREATER NOIDA WEST Bisrakh Gautam Buddha Nagar ,
                  Uttar Pradesh.<br /><br />
                  <strong>Phone:</strong> +91 9625962023<br />
                  <strong>Email:</strong> indianextgen@gmail.com<br />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            © Copyright <strong><span>IndiaNextGen</span></strong>. All Rights Reserved
          </div>
          <div className="credits">
            Designed by <a href="https://bootstrapmade.com/">IndiaNextGen</a>
          </div>
        </div>
      </footer>
    )
}
export default Footer;