import React from "react";
import './AboutUs.css';
import img1 from '../../img/homeBanner.webp';

const AboutUs = ()=>{
              return (
                <>
                <div className="career-page">
                      <div className="container-fluid">
                        <p>
                          About Us
                        </p>
                      </div>

                    </div>  
                  <footer id="footer" className="footer">
                    <div className="footer-newsletter">
                      <div className="container">
                        <div className='row main-card-section' style={{marginTop:'80px'}}>
                                <div class="col-md-4 mt-5">
                                    <div class="card" style={{border:'none'}}>
                                        <div class="card-body">
                                            <h5 class="card-title main-head">Innovation</h5>
                                            <p class="card-text main-desc" style={{fontSize: '14px'}}>
                                                IndiaNextGen leverages its vast expertise, knowledge, and commitment to
                                                delivering comprehensive multi-service solutions across major industry
                                                verticals such as Financial Services, Manufacturing, Consumer Services,
                                                Public Services, and Healthcare. IndiaNextGen is proud of its "Employee
                                                First" philosophy that empowers our staff to generate genuine value for
                                                our customers.
                                            </p>
                                            {/* <a href="#" class="btn">Go somewhere</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mt-5">
                                    <div class="card" style={{border:'none'}}>
                                        <div class="card-body">
                                            <h5 class="card-title main-head">Impact</h5>
                                            <p class="card-text main-desc" style={{fontSize: '14px'}}>
                                                IndiaNextGen provides a broad spectrum of specialized services to cater
                                                to all your business requirements. Our team of designers, developers,
                                                SEO, Business Analyst and internet marketing/search engine marketing
                                                consultants possess extensive experience in the field of internet
                                                technologies, allowing us to deliver high-quality solutions to our
                                                esteemed clients.
                                            </p>
                                            {/* <a href="#" class="btn">Go somewhere</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mt-5">
                                    <div class="card" style={{border:'none'}}>
                                        <div class="card-body">
                                            <h5 class="card-title main-head">Scalability</h5>
                                            <p class="card-text main-desc" style={{fontSize: '14px'}}>
                                                IndiaNextGen follows a well-established set of quality processes and
                                                rigorous standards in all our projects. This approach guarantees that
                                                our clients receive numerous benefits, including reduced costs,
                                                minimized risks, increased efficiency, and optimal resource utilization.
                                            </p>
                                            {/* <a href="#" class="btn">Go somewhere</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-10 fifth-section'>
                        <div class="card">
                            <div class="card-body">
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <h1>Why Join Our Team</h1>
                                        <p>Our mission is to empower individuals and organizations to realize their full
                                            potential by offering value-based programs and products that provide a
                                            competitive advantage. We achieve this by emphasizing the development of
                                            positive attitudes, leadership skills, motivation, and values that enhance
                                            productivity.</p>
                                        <button>JOIN NOW</button>
                                    </div>
                                    <div className='col-md-4 mt-4'>
                                        <img src={img1}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                      </div>
                    </div>
                  </footer>
                  </>
              );
}
export default AboutUs;